.header-ctn {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  margin: auto;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.884);
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.nav-link {
  width: max-content;
  margin-left: 42%;
  color: white;
  font-size: 2rem;
}

.nav-link:hover {
  color: #08b8a9;
}
@media screen and (max-width: 425px) {
  .nav-link {
    margin-left: 26%;
  }
}
@media screen and (max-width: 400px) {
  .nav-link {
    margin-left: 25%;
  }
}
@media screen and (max-width: 330px) {
  .nav-link {
    margin-left: 23%;
  }
  .header-ctn {
  }
}
