.App {
  width: 1080px;
  margin: auto;
  text-align: center;
  color: black;
  background: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 600px){
  .App{
    width: 360px;
  }
}
@media screen and (max-width: 365px){
  .App{
    width: 340px;
  }
}
@media screen and (max-width: 330px) {
  .App{
    width: 355px;
  }
}

/* Tablets */
/* @media screen and (max-width: 1030px){
  .App{
    width: 1010px;
  }
} */