.landing {
  text-align: center;
  background-image: url("../../assets/Programmer1440x6401.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 975px;
}

.intro-text {
  width: 30%;
  position: absolute;
  left: 36%;
  top: 30%;
  font-size: 2rem;
  line-height: 12 * 1.618;
  color: white;
  text-shadow: 2px 2px 4px #000;
  font-family: 'Cantarell';
  font-weight: 600;
}

.next-text {
  position: absolute;
  width: 15%;
  bottom: 11%;
  left: 43.5%;
  font-size: 1.6rem;
  color: white;
  font-family: 'Cantarell';
  text-shadow: 2px 2px 4px #000;
}
@media screen and (max-width: 1400px){
  .landing{
    height: 660px;
  }
  .next-text{
    bottom: 3%;
  }
}
@media screen and (max-width: 600px) {
  .landing {
    height: 645px;
  }
  .intro-text {
    top: 30%;
    width: 60%;
    left: 5%;
  }
  .next-text {
    width: 30%;
    left: 30%;
  }
}
@media screen and (max-width: 425px){
  .landing{
    width: 426px;
    height: 735px;
  }
  .intro-text{
    left: 19%;
    top: 20%;
  }
  .next-text{
    left: 35%;
  }
}
@media screen and (max-width: 375px) {
  .landing{
    max-width: 380px;
    height: 660px;
  }
  .intro-text{
    font-size: 1.8rem;
    top: 24%;
    left: 19%;
  }
  .next-text{
    left: 33%;
    bottom: 9.5%;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 330px) {
  .landing{
    width: 350px;
    height: 625px;
  }
  .intro-text{
    font-size: 1.8rem;
    top: 14%;
    left: 24%;
  }
  .next-text{
    left: 38%;
    bottom: -50px;
  }
}
/* @media screen and (min-height: 750px ){
  .landing{
    height: 800px;
  }
} */
/* Tablets */
/* @media screen and (max-width: 1030px){
  .landing{
    width: 1030px;
  }
  .intro-text{
    left: 55%;
    top: 20%;
  }
  .next-text{
    bottom: 25%;
    left: 63%;
  }
} */
/* 
@media screen and (max-width: 1370px){
  .intro-text{
    left: 35%;
  }
  .next-text{
    bottom: 43%;
    left: 42%;
  }
} */