.projects-ctn {
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 30px;
}

.project {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.rev-project {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 60px;
}
.info-ctn{
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 400px;
}
.text-title {
  width: 550px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 12 * 1.618;
}
.proj-nav-ctn{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  padding-top: 15px;
  height: 40px;
  border: 2px solid black;
}
.proj-link{
  color: black;
}
.proj-link:hover {
  color: #08b8a9;
}
.project-title {
  font-weight: 900;
  font-size: 1.6rem;
  color: #08b8a9;
  width: 100%;
  order: 0;
  font-family: "Cantarell";
}
.project-pic {
  width: 500px;
  min-width: 500px;
  height: 400px;
  min-height: 400px;
  border: 2px solid black;
  border-radius: 25px;
  box-shadow: 10px 10px 5px grey;
}
.tech-ctn {
  max-width: 75%;
  margin-top: 2%;
  margin-left: 13%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.tech-item {
  padding: 5px;
  line-height: 2.5rem;
  margin-right: 2%;
  box-shadow: 5px 5px 5px grey;
  color: black;
  background-color: #00cab9;
  font-weight: 500;
  font-family: "Cantarell";
  border-radius: 25px;
}
@media screen and (max-width: 1400px) {
  .text-title {
    width: 420px;
  }
}

@media screen and (max-width: 600px) {
  .info-ctn{
    height: 380px;
  }
  .proj-nav-ctn {
  }
  .project-pic {
    max-width: 350px;
    min-width: 200px;
    height: 200px;
    min-height: 200px;
  }
  .project {
    flex-flow: column wrap;
  }
  .rev-project {
    flex-flow: column wrap;
  }
  .text-title {
    padding: 3%;
    width: 370px;
  }
}
@media screen and (max-width: 375px) {
  .project-pic {
    max-width: 270px;
  }
  .text-title {
    padding: 8%;
    width: 300px;
    font-size: 1rem;
  }
}
