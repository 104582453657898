.hamburger-exit {
  position: fixed;
  color: white;
  right: 20px;
  top: 0px;
  padding: 10px;
  font-size: 1.5rem;
  z-index: 1000;
}
.hamburger-ctn {
  position: fixed;
  right: 3%;
  top: 1px;
}
.hamburger-bar {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 5px 0;
  border-radius: 25px;
}

@media screen and (max-width: 600px){
    .hamburger-ctn{
        right: 15%;
    }
}
@media screen and (max-width: 330px) {
    .hamburger-ctn{
      top: 2%;
      right: 11%;
    }
}