.about-ctn {
  width: 100%;
  font-size: 1.6rem;
}
.about-header{
    font-family: 'Cantarell';
    text-align: center;
}
.about-text {
  width: 550px;
  font-size: 1.2rem;
  line-height: 12 * 1.618;
  text-align: center;
  margin-left: 24%;
}
.skills-ctn{
    max-width: 90%;
    padding: 0% 10%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    line-height: 3rem;
}
.skill-item{
    margin: .5%;
    padding: 1%;
    font-size: 1.3rem;
    border: 1px solid black;
    box-shadow: 5px 5px 5px grey;
    background-color: #00cab9;
    font-family: 'Cantarell';
    border-radius: 25px;
}

@media screen and (max-width: 600px){
    .about-text{
        width: 90%;
        margin-left: 3%;
    }
}
@media screen and (max-width: 365px) {
    .about-text{
        font-size: 1rem;
    }
}